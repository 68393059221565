import React, { useState } from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import styled from "styled-components";
import Nav from "../components/Nav";
import SEO from "../components/SEO";


const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  max-width: 1000px;
  margin: 20px auto;
`;
const BlogPost = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  background-color: white;
  margin: 5px 0;
  min-height: 50px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export default function Blog({ data }) {
  data.allSanityBlog.nodes.sort(
    (a, b) => Date.parse(b.date) - Date.parse(a.date)
  );
  return (
    <Wrapper>
    <SEO title="Blog" />
      <Nav />
      <div>
        {data.allSanityBlog.nodes.map((blog) => (
          <BlogPost key={`post-${blog.slug.current}`}>
            <Link to={`/blog/${blog.slug.current}`}>
              <h4>{blog.title}</h4>
            </Link>
            <h6>{blog.date}</h6>
          </BlogPost>
        ))}
      </div>
      
    </Wrapper>
  );
}
export const query = graphql`
  query BloqQuery {
    allSanityBlog {
      nodes {
        title
        date
        _rawBody(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
      }
    }
  }
`;
